import { type } from 'rambdax';

//
// === CONSTANTS ===============================================================
export const OBJECT = 'Object' as const;
export const NUMBER = 'Number' as const;
export const BOOLEAN = 'Boolean' as const;
export const STRING = 'String' as const;
export const NULL = 'Null' as const;
export const ARRAY = 'Array' as const;
export const REGEXP = 'RegExp' as const;
export const NAN = 'NaN' as const;
export const FUNCTION = 'Function' as const;
export const UNDEFINED = 'Undefined' as const;
export const ASYNC = 'Async' as const;
export const PROMISE = 'Promise' as const;
export const SYMBOL = 'Symbol' as const;
export const SET = 'Set' as const;
export const ERROR = 'Error' as const;
export const MAP = 'Map' as const;
export const WEAKMAP = 'WeakMap' as const;
export const GENERATOR = 'Generator' as const;
export const GENERATOR_FUNCTION = 'GeneratorFunction' as const;
export const BIGINT = 'BigInt' as const;
export const ARRAY_BUFFER = 'ArrayBuffer' as const;
export const DATE = 'Date' as const;

//
// === METHODS =================================================================
export type DataType =
  | typeof OBJECT
  | typeof NUMBER
  | typeof BOOLEAN
  | typeof STRING
  | typeof NULL
  | typeof ARRAY
  | typeof REGEXP
  | typeof NAN
  | typeof FUNCTION
  | typeof UNDEFINED
  | typeof ASYNC
  | typeof PROMISE
  | typeof SYMBOL
  | typeof SET
  | typeof ERROR
  | typeof MAP
  | typeof WEAKMAP
  | typeof GENERATOR
  | typeof GENERATOR_FUNCTION
  | typeof BIGINT
  | typeof ARRAY_BUFFER
  | typeof DATE;

export function of(input: unknown): DataType {
  return type(input);
}

export function isFunction(input: unknown): input is Function;
export function isFunction(input: unknown) {
  return type(input) === FUNCTION;
}
