import { isEmpty, isNil } from 'rambdax';

import { DataType } from '@ping/utils';

const SEARCH_VALUE_PATTERN = '%s' as const;

/**
 * It takes a dictionary of translations, a string to translate, and a list of parameters.
 * It translates the text while replaces all the %s with the params passed in respectively.
 * @param translations - Record<string, string> - this is the object that contains all the
 * translations.
 * @param {string} text - the string to translate
 * @param {string[]} params - Record<string, string> - this is the object that contains all the
 * translations.
 */
export const translate = (translations: Record<string, string>, text: string, ...params: (string | number)[]) => {
  //
  // convention to replace params inside text with %s, so we can replace them with real values
  const pattern = new RegExp(SEARCH_VALUE_PATTERN);

  return (params || []).reduce<string>(
    (translation, param) => translation.replace(pattern, String(param)),
    translations?.[text] || text
  );
};

// TODO: to work properly and shows the ts error we need to set `"strictNullChecks": true` in tsconfig.json.
type Stringifiable = Exclude<{ toString(): string }, null | undefined>;

export function createTranslator(getTranslations: () => Record<string, string>, pattern = '%s' as const) {
  console.assert(DataType.isFunction(getTranslations), 'getTranslations must be a function');

  function createTemplateText(segments: readonly string[], pattern: string, output = ''): string {
    if (isEmpty(segments) || isNil(segments)) {
      return output.slice(0, -pattern.length);
    }

    const [firstSegment = '', ...restSegments] = segments;
    return createTemplateText(restSegments, pattern, `${output}${firstSegment}${pattern}`);
  }

  function createDisplayText(params: readonly Stringifiable[], pattern: string, output = ''): string {
    if (isEmpty(params) || isNil(params)) {
      return output;
    }

    const [firstParam = '', ...restParams] = params;
    return createDisplayText(restParams, pattern, output.replace(pattern, firstParam.toString()));
  }

  function getTranslation(of: string) {
    return getTranslations()?.[of] || of;
  }

  /**
   * Translates the text.
   *
   * @param {TemplateStringsArray} segments - The template strings to be translated.
   * @param {...Stringifiable[]} params - The parameters to be used in the translation.
   */
  return (segments: readonly string[], ...params: readonly Stringifiable[]) => {
    console.assert(!isNil(segments), 'segments must not be nil');
    console.assert(!isNil(params), 'params must not be nil');

    const template = createTemplateText(segments, pattern);
    return createDisplayText(params, pattern, getTranslation(template));
  };
}
